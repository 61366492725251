import { createStore } from 'vuex'
import {
  languageKey,
  localTokenKey,
  localUserInfoKey,
  defaultLang
} from '@/Constant';


const language = localStorage.getItem(languageKey);
const token = localStorage.getItem(localTokenKey);
let userInfoStr = localStorage.getItem(localUserInfoKey);
let userInfo = {}
if (userInfoStr && typeof(userInfoStr) == String) {
  userInfo = JSON.parse(userInfoStr);
}

export default createStore({
  state: {
    userInfo: userInfo || {},
    lang: language || defaultLang,
    token: token,
    downloadUrl: null,
    newUserFlag: false,
    currentNotice: {},
  },
  getters: {
    token: state => state.token,
    lang: state => state.lang,
  },
  mutations: {
    changeLang(state, lang) {
      if (lang) {
        state.lang = lang;
        localStorage.setItem(languageKey, lang);
      }
    },
  },
  actions: {
  },
  modules: {
  }
})
