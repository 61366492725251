import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'AboutUs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutUs.vue')
  },
  {
    path: '/how',
    name: 'HowToPlay',
    component: () => import(/* webpackChunkName: "about" */ '../views/HowToPlay.vue')
  },
  {
    path: '/team',
    name: 'TeamDescription',
    component: () => import(/* webpackChunkName: "about" */ '../views/TeamDescription.vue')
  },
  {
    path: '/protocol',
    name: 'Protocol',
    component: () => import(/* webpackChunkName: "about" */ '../views/Protocol.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/pay',
    name: 'Pay',
    component: () => import(/* webpackChunkName: "about" */ '../views/Pay.vue')
  },
  {
    path: '/upay',
    name: 'UUUpay',
    component: () => import(/* webpackChunkName: "about" */ '../views/UUUpay.vue')
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import(/* webpackChunkName: "about" */ '../views/Download.vue')
  },
  {
    path: '/install',
    name: 'Install',
    component: () => import(/* webpackChunkName: "about" */ '../views/Install.vue')
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
