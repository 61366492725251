export default {
    aboutUS: {
        navTitle: "About Us",
        describe: "UUUPay is part of Galaxy Entertainment. The group is headquartered in Edinburgh, Scotland. It is one of the world's leading football betting groups. It provides customers with a wide range of betting on all sports, including football, tennis, basketball, snooker and table tennis. sports betting. The group provides football match betting services in Europe, America, Africa and other places. It is the official designated partner of the European Cup of Nations. It has established more than 30 offices around the world, has more than 2,600 offline betting shops, and more than 176,000 terminal betting equipment. , through satellite live broadcast of game information to achieve accuracy.",
        sp_title: "Service philosophy",
        sp_value: "The UUUPay entertainment platform adheres to its fine tradition and insists on providing professional, honest and reliable services in exchange for the trust and support of the public.",
        h_title: "Honesty",
        h_value: "The UUUPay entertainment platform uses Internet technology and relies on the reputation of Galaxy Casino to create a new football sports online casino! As an international professional online gambling game operator, we promise to provide every customer with the safest and fairest gambling games and a full range of services. The game results are fair, just and open!"
    },
    howToPlay: {
        navTitle: "How To Play",
        describe: "Anti-wave gallbladders first originated in the United Kingdom and are called sports hedging funds. They are mainly used for hedging in football entertainment activities to cope with the risk of losses in football entertainment activities. This is related to the tolerance of the local football entertainment culture. Later, the independent gameplay of anti-wave guts was extended. In view of its low entry threshold, low difficulty, and wide audience, it is deeply loved by the majority of players.",
        rule1: "1. Bodan is to predict the specific game score. Only if the prediction is correct can you get the corresponding odds. Anti-wave courage means predicting an incorrect score. As long as the investor does not select the result of this event among the 17 score choices, the investor will win the score, with a success rate of 16/17.",
        rule2: "2. The platform has an extra limit of 4 4:4 football matches per day for members. Even if the score is really 4:4, members’ principal will be returned. Even if you don’t understand football game analysis, you can still get about 2% of the principal every day. Stable income makes football projects not just a game, but also an investment.",
        rule3: "3. For member withdrawals, 10% of the withdrawal amount will be charged as a service fee to maintain platform operating expenses."
    },
    teamDescription: {
        navTitle: "Team Description",
        rule1: "1. Invited subordinates who complete the first recharge will be rewarded with 5% of the recharge amount.",
        rule2: "2. You can get rebates from the profits of lower-level team members. The rebate ratio is 10% for first-level members, 6% for second-level members, and 1% for third-level members. For example, A invites B, B invites C, C invites D, and B Is a first-level member of team A, C is a second-level member of team A, and D is a third-level member of team A. A can get 10% of B’s betting profit, and can get 6% of C’s betting profit. D 1% of betting profit.",
        rule3: "3. Preferential policy on withdrawal service fees. If there are 5 people in the first-level team, the withdrawal service fee will be 8%. If there are 15 people in the first-level team, the withdrawal service fee will be 6%. If there are 30 people in the first-level team, the withdrawal service fee will be 5%. The first-level team has 60 people, and the withdrawal service fee is 3%. The first-level team has 100 people, and the withdrawal service fee is 1%."
    },
    regist: {
        title1: 'Register to download the APP',
        title2: '',
        registrarse: 'Register',
        placeholder_phone: 'Please enter your phone number',
        placeholder_pwd: 'Please enter your password',
        placeholder_country: 'Please select a country',
        placeholder_iCode: 'Invitation code',
        registered_phone_msg: 'This phone number has been registered, please try again.',
        registered_pwd_inc: 'Inconsistent passwords',
        picker_confirm_text: 'Confirm',
        picker_cancel_text: 'Cancel'
    },
    "cancel": "Cancel",
    "confirm": "Confirm",
    pay: {
        navTitle: 'Bank Payment',
        warning: 'Warning',
        warnText1: 'Warning: Please be sure to fill in the payment voucher after therecharge.',
        warnText2: '(The payment voucher is in the text message you received after successful payment)',
        amount: 'Amount',
        copy: 'Copy',
        copySuccess: 'Copy success',
        orderNo: 'OrderNo',
        accountName: 'Account Name',
        accountNo: 'Account Number',
        bankNo: 'Bank number',
        submit: "Confirm",
        phoneTip: "Please enter your payment phone number",
    },
    upay: {
        orderNumber: 'Order number',
        orderAmount: 'Order amount',
        consumer: 'Operator',
        phoneNumber: 'Payment phone number',
        processTitle: 'Turnaround process:',
        tips1: `1.Dial *150*00#<br>
            2.Select 1 Tuma pesa<br>
            3.Select 1 Weka Namba ya Simu<br>
            4.Enter the cashier's mobile phone number 07475xxxxx<br>
            5.Enter the transfer amount<br>
            6.Enter pin<br>
            7.confirm`,
        tips2: `1.Dial *150#<br>
            2.Select 1<br>
            3.Select 1<br>
            4.Select 1<br>
            5.Enter your mobile phone number on the order page 07475xxxxx<br>
            6.Enter the amount on the order page<br>
            7.Enter pin<br>
            8.Select 1`,
        tips3: `1.Dial *898#<br>
            2.Enter your mobile phone number on the order page 07475xxxxx<br>
            3.Enter the amount on the order page<br>
            4.Enter remarks<br>
            5.Enter pin<br>
            6.Select 1 confirm`
    },
    protocol: {
        navTitle: 'User Terms of Service and Privacy Agreement',
        t1: '1. User Terms of Service',
        p1: 'Important information:',
        p2: "This Agreement is a legal agreement between you (an individual or a single entity) and the developer of this application regarding the developer's provision of services related to this application to you. Here is a special reminder that users (you) who want to use related services must carefully read the terms of these Terms of Service in advance, including disclaimers that exempt or limit developers’ responsibilities and restrictions on users’ rights. Please review and accept or not accept these Terms of Service (minors should be accompanied by a legal guardian when reviewing). Once these Terms of Service are changed, the modified content will be posted on the web page. Once the revised Terms of Service are published on the web page, they will effectively replace the original Terms of Service. You can log in to the website at any time to view the latest version of the Terms of Service. If you do not agree to the modifications to the terms of service, you should not use or actively cancel the relevant services. Otherwise, any of your logging in, viewing, etc. use of the relevant services will be deemed your understanding and acceptance of the relevant modifications and this Agreement. .",
        p3: "If you choose to accept this agreement and activate the service, it means that you agree to be bound by the conditions of the agreement. If you do not agree to these Terms of Service, you do not have the right to use the Service. If you violate any of the provisions of these Terms, the developer has the right to suspend or terminate your qualifications to use the service at any time and reserves the right to pursue relevant legal liabilities.",
        p4: "Unless otherwise specified, the following terms in this Agreement have the following meanings:",
        p5: "Developer: Galaxy Entertainment",
        p6: "Application: UUUPay",
        p7: "1. This application and service are developed by developers and protected by international copyright treaties and other intellectual property laws and treaties.",
        p8: "2. Users may use the application and enjoy services in accordance with this agreement on the premise of complying with the law and these terms, but users do not have the right to perform actions including but not limited to the following:",
        p8_1: "2.1 Delete all copyright information and content on the application and other copies;",
        p8_2: "2.2 Reverse engineering, reverse assembly, reverse compilation, etc. of the application;",
        p8_3: '2.3 Regarding the relevant information in the application, without the written consent of the developer, users are not allowed to carry out actions including but not limited to the following: use, copy, modify, link, reproduce, compile, publish, publish, establish mirror sites, use "software without authorization" "Develop related derivative products, works, etc',
        p8_4: "2.4 Use applications and services to publish, transmit, disseminate, and store content that endangers national security, the reunification of the motherland, and social stability, or content that is insulting, defamatory, pornographic, violent, causes anxiety to others, and any content that violates national laws, regulations, and policies.",
        p8_5: "2.5 Use to publish, transmit, disseminate, or store content that infringes other people’s intellectual property rights, trade secret rights, and other legitimate rights.",
        p8_6: '2.6 Carry out any behavior that endangers computer network security, including but not limited to: using unauthorized data or entering unauthorized servers/accounts; entering public computer networks or other people’s computer systems without permission and deleting, modifying, and adding stored information ; Attempt to probe, scan, and test the weaknesses of this "Software" system or network without permission, or conduct other actions that undermine network security; Attempt to interfere with or disrupt the normal operation of this "Software" system or website, and intentionally spread malicious programs or viruses and other behaviors that disrupt and interfere with normal network information services; forge TCP/IP packet names or partial names.',
        p9: "3. Users must understand that the use of this service product involves Internet services and may be affected by unstable factors in various links. Therefore, the service is subject to the risk of service interruption or failure to meet user requirements caused by force majeure, computer viruses or hacker attacks, system instability, user location, user shutdown, and any other technical, Internet, communication line reasons, etc.",
        p10: "4. Users must understand that when using this service, there will be any anonymous or anonymous content or behavior from any other person, including threatening, defamatory, objectionable or illegal content or behavior that infringes on the rights of others (including intellectual property rights). Users must bear the risk of impersonating information. The developer does not make any type of guarantee for the service, whether explicit or implicit, including the authenticity, merchantability, suitability for a particular purpose, and ownership of all relevant information. and non-infringement implied warranties and conditions, and shall not be liable for any direct, indirect, incidental, special and subsequent damages resulting from the user's improper or illegal use of the service.",
        p11: "5. The use of this service must comply with relevant national laws and policies, safeguard national interests, protect national security, and comply with these terms. All responsibilities arising from the user's illegal use or violation of this agreement (including but not limited to speech publication, transmission, etc.) , the user bears full responsibility.",
        p12: "6．Respecting the privacy of users' personal privacy information is the principle of this application and services. The developer will take reasonable measures to protect users' personal privacy information. Unless required by law or government departments with legal authority or with user consent, etc. With the user's consent, the user's personal privacy information will not be disclosed to third parties.",
        p13: "7. Users are particularly reminded that when using the Internet, they must abide by relevant national policies and laws, such as criminal law, national security law, confidentiality law, computer information system security protection regulations, etc., to protect national interests and national security, and for those caused by illegal use of the Internet. All responsibilities rest with the user.",
        p13_1: "7.1. Users shall not use this application and services to send or disseminate any information that hinders social order or is illegal, false, harassing, insulting, threatening, harmful, destructive, provocative, obscene and pornographic.",
        p13_2: "7.2. Users are not allowed to use this application and services to send or disseminate sensitive information and information that violates the national legal system.",
        p13_3: "7.3. This application and service, like most Internet products, is susceptible to various security issues, including but not limited to:",
        p13_3_1: "1) Reveal detailed personal information and be used by criminals to cause harassment in real life.",
        p13_3_2: "2) Deceive and decipher codes.",
        p13_3_3: '3) Other software downloaded and installed contains "Trojan horses" and other viruses, which threaten the security of information and data on personal computers, and then threaten the use of this application and services.',
        p14: "8. Users are advised not to disclose their various property accounts, bank cards, credit cards and corresponding passwords and other important information when using this application and services. Otherwise, any losses caused thereby will be borne by the users themselves.",
        p15: "9. Users should use this application and services in a standardized and legal manner. If a user disrupts, harasses, deceives other users in a public environment, or commits other violations of this Agreement, the developer has the right to stop the service once discovered.",
        p16: "10. Users must understand that in order to meet the needs of the overall operation of this application, the developer has the right to modify or interrupt, suspend or terminate the service at any time without notifying you. The developer does not need to be responsible to you or any third party for exercising the aforementioned rights. or assume any liability for compensation.",

        t2: "2. User Privacy Agreement",
        u1: "This application respects and protects the privacy of all users of the service. In order to provide you with more accurate and personalized services, this application will use and disclose your personal information in accordance with this Privacy Policy. However, this application will treat this information with a high degree of diligence and prudence. Except as otherwise provided in this Privacy Policy, this application will not disclose or provide this information to third parties without your prior permission. This application will update this privacy policy from time to time. When you agree to this Application Service Use Agreement, you are deemed to have agreed to the entire content of this Privacy Policy. This privacy policy is an integral part of the agreement to use this application service.",
        u2: "1. Scope of application",
        u2_1: "(a) When you register for this application account, you provide the personal registration information required by this application;",
        u2_2: "(b) When you use this application network service or visit this application platform webpage, this application automatically receives and records the information on your browser and computer, including but not limited to your IP address, browser type, Data such as the language used, access date and time, software and hardware feature information, and web page records you require;",
        u2_3: "(c) User personal data obtained by this application from business partners through legal channels.",
        u3: "You understand and agree that the following information does not apply to this privacy policy:",
        u3_1: "(a) The keyword information you enter when using the search service provided by this application platform;",
        u3_2: "(b) The relevant information and data collected by this application that you publish on this application, including but not limited to participation in activities, transaction information and evaluation details;",
        u3_3: "(c) Violation of legal provisions or violations of the rules of this application and the measures this application has taken against you.",
        u4: "2. Use of information",
        u4_1: "(a) This application will not provide, sell, rent, share or trade your personal information to any unrelated third party, unless you obtain your permission in advance, or the third party and this application (including this application's affiliates) individually or jointly To provide you with services, and after the service ends, it will be prohibited from accessing all such materials including those it was previously able to access.",
        u4_1: "(b) This application also does not allow any third party to collect, edit, sell or disseminate your personal information for free by any means. If any user of this application platform engages in the above activities, upon discovery, this application has the right to immediately terminate the service agreement with that user.",
        u4_1: "(c) For the purpose of serving users, this application may use your personal information to provide you with information of interest to you, including but not limited to sending you product and service information, or sharing information with this application partners so that they can To send you information about its products and services (the latter requires your prior consent).",
        u5: "3. Information disclosure",
        u6: "Under the following circumstances, this application will disclose all or part of your personal information based on your personal wishes or legal provisions:",
        u6_1: "(a) Disclosed to third parties with your prior consent;",
        u6_2: "(b) Your personal information must be shared with third parties in order to provide the products and services you request;",
        u6_3: "(c) Disclosed to third parties or administrative or judicial agencies in accordance with the relevant provisions of the law or the requirements of administrative or judicial agencies;",
        u6_4: "(d) If you violate relevant laws, regulations, this application service agreement or related rules, you need to disclose it to a third party;",
        u6_5: "(e) If you are a qualified intellectual property complainant and have filed a complaint, disclose it to the respondent at the request of the respondent so that both parties can handle possible rights disputes;",
        u6_6: "(f) In a transaction created on this application platform, if any party to the transaction performs or partially performs its transaction obligations and makes a request for information disclosure, this application has the right to decide to provide the user with the contact information of the counterparty and other necessary information. Information to facilitate the completion of transactions or resolution of disputes.",
        u6_7: "(g) Other disclosures that this application deems appropriate in accordance with laws, regulations or website policies.",
        u7: "4. Information storage and exchange",
        u8: "The information and materials collected by this application about you will be stored on the servers of this application and/or its affiliated companies. These information and materials may be transferred to your country, region or outside the country where this application collects information and materials, and in Accessed, stored and displayed overseas.",
        u9: "5. Information security",
        u9_1: '(a) This application account has security protection functions. Please keep your username and password information properly. This application will use security measures such as encrypting user passwords to ensure that your information is not lost, abused or altered. Despite the aforementioned security measures, please also note that there are no "perfect security measures" on the information network.',
        u9_2: '(b) When using this application network service for online transactions, you will inevitably disclose your personal information, such as contact information or postal address, to the counterparty or potential counterparty. Please protect your personal information properly and provide it to others only when necessary. If you find that your personal information has been leaked, especially the username and password of this application, please contact the customer service of this application immediately so that this application can take corresponding measures.',
        u10: "6. Changes to this Privacy Policy",
        u10_1: "(a) If we decide to change our privacy policy, we will post these changes in this policy, on our company website and where we deem appropriate so that you can understand how we collect and use your personal information and who can access this information, and under what circumstances we will disclose this information.",
        u10_1: "(b) The company reserves the right to modify this policy at any time, so please check it frequently. If there are any major changes to this policy, the company will notify you through a website notification.",
        end: "Please protect your personal information properly and provide it to others only when necessary. If you find that your personal information has been leaked, especially the username and password of this application, please contact the customer service of this application immediately so that this application can take corresponding measures."
    }
}