export default {
    aboutUS: {
        navTitle: "À propos de nous",
        describe: "UUUPay fait partie de Galaxy Entertainment. Le groupe a son siège à Édimbourg, en Écosse. Il est l'un des principaux groupes de paris sur le football au monde. Il propose à ses clients une large gamme de paris sur tous les sports, notamment le football, le tennis, le basket-ball, le snooker et la table. tennis, paris sportifs. Le groupe fournit des services de paris sur les matchs de football en Europe, en Amérique, en Afrique et ailleurs. Il est le partenaire officiel désigné de la Coupe d'Europe des Nations. Il a établi plus de 30 bureaux dans le monde, compte plus de 2 600 boutiques de paris hors ligne et plus de 176 000 équipements de paris sur terminaux. , grâce à la diffusion en direct par satellite des informations de jeu pour atteindre la précision.",
        sp_title: "Philosophie de service",
        sp_value: "La plateforme de divertissement UUUPay adhère à sa belle tradition et insiste sur la fourniture de services professionnels, honnêtes et fiables en échange de la confiance et du soutien du public.",
        h_title: "Honnêteté",
        h_value: "La plateforme de divertissement UUUPay utilise la technologie Internet et s'appuie sur la réputation de Galaxy Casino pour créer un nouveau casino en ligne de sports de football ! En tant qu'opérateur professionnel international de jeux de hasard en ligne, nous nous engageons à fournir à chaque client les jeux de hasard les plus sûrs et les plus équitables ainsi qu'une gamme complète de services. Les résultats du jeu sont équitables, justes et ouverts !"
    },
    howToPlay: {
        navTitle: "Comment jouer",
        describe: "Les vésicules biliaires anti-ondes sont originaires du Royaume-Uni et sont appelées fonds de couverture sportive. Ils sont principalement utilisés pour couvrir les activités de divertissement de football afin de faire face au risque de pertes dans les activités de divertissement de football. Ceci est lié à la tolérance du divertissement de football local. culture. Plus tard, le gameplay indépendant d'Anti-wave Guts a été étendu et, compte tenu de son faible seuil d'entrée, de sa faible difficulté et de son large public, il est profondément apprécié par la majorité des joueurs.",
        rule1: "1. Bodan doit prédire le score spécifique du jeu. Ce n'est que si la prédiction est correcte que vous pouvez obtenir les cotes correspondantes. Le courage anti-vague consiste à prédire un score erroné. Tant que l'investisseur ne sélectionne pas le résultat de cet événement parmi les 17 choix de score, l'investisseur remportera le score, avec un taux de réussite de 16/17.",
        rule2: "2. La plateforme a une limite supplémentaire de 4 matchs de football 4: 4 par jour pour les membres. Même si le score est réellement de 4: 4, le capital des membres sera restitué. Même si vous ne comprenez pas l'analyse des matchs de football, vous pouvez recevez toujours environ 2% du principal chaque jour. Des revenus stables font des projets de football non seulement un jeu, mais aussi un investissement.",
        rule3: "3. Pour les retraits des membres, 10 % du montant du retrait sera facturé à titre de frais de service afin de maintenir les dépenses de fonctionnement de la plateforme."
    },
    teamDescription: {
        navTitle: "Description de l'équipe",
        rule1: "1. Les subordonnés invités qui terminent la première recharge seront récompensés par 5 % du montant de la recharge.",
        rule2: "2. Vous pouvez obtenir des remises sur les bénéfices des membres de l'équipe de niveau inférieur. Le taux de remise est de 10 % pour les membres de premier niveau, de 6 % pour les membres de deuxième niveau et de 1 % pour les membres de troisième niveau. Par exemple, A invite B, B invite C, C invite D et B est un membre de premier niveau de l'équipe A, C est un membre de deuxième niveau de l'équipe A et D est un membre de troisième niveau de l'équipe A. A peut obtenir 10 % du bénéfice des paris de B et peut obtenir 6 % du bénéfice des paris de C. D 1 % du bénéfice des paris.",
        rule3: "3. Politique préférentielle sur les frais de service de retrait. S'il y a 5 personnes dans l'équipe de premier niveau, les frais de service de retrait seront de 8 %. S'il y a 15 personnes dans l'équipe de premier niveau, les frais de service de retrait seront de 6 %. . S'il y a 30 personnes dans l'équipe de premier niveau, les frais de service de retrait seront de 5 %. L'équipe de premier niveau compte 60 personnes et les frais de service de retrait sont de 3 %. L'équipe de premier niveau compte 100 personnes, et les frais de service de retrait sont de 1%."
    },
    regist: {
        title1: 'Inscrivez-vous pour télécharger l\'APP',
        registrarse: 'S\'inscrire',
        placeholder_phone: 'Veuillez entrer votre numéro de téléphone',
        placeholder_pwd: 's\'il vous plait entrez votre mot de passe',
        placeholder_country: 'Veuillez sélectionner un pays',
        placeholder_iCode: 'Code d\'invitation',
        registered_phone_msg: 'Ce numéro de téléphone a été enregistré, veuillez réessayer.',
        registered_pwd_inc: 'Mots de passe incohérents',
        picker_confirm_text: 'Confirmer',
        picker_cancel_text: 'Annuler'
    },
    "confirm": "Confirmer",
    "cancel": "Annuler",
    pay: {
        navTitle: 'Paiement bancaire',
        warning: 'Avertissement',
        warnText1: 'Avertissement：S’il vous plaît assurez-vous de remplir le bon de paiement après le paiement.',
        warnText2: '(Le bon de paiement se trouve dans le SMS que vous avez reçu après le paiement réussi)',
        amount: 'Quantité',
        copy: 'Copier',
        copySuccess: 'Copie réussie',
        orderNo: 'N° de commande',
        accountName: 'Nom du compte',
        accountNo: 'Numéro de compte',
        bankNo: 'Numéro de banque',
        submit: "Confirmé",
        phoneTip: "Veuillez entrer votre numéro de téléphone de paiement",
    },
    upay: {
        orderNumber: 'Numéro de commande',
        orderAmount: 'Montant de la commande',
        consumer: 'Opérateur',
        phoneNumber: 'Numéro de téléphone de paiement',
        processTitle: 'Processus de rotation:',
        tips1: `
            Composez *133#<br>
            Étape 1: La première étape consiste à en choisir 3<br>
            Étape 2: Choisissez 1 à la deuxième étape<br>
            Étape 3: Saisissez le numéro de téléphone mobile à 10 chiffres ci-dessus<br>
            Étape 4: Saisissez le montant de la commande xxx<br>
            Étape 5: Choisissez-en 1<br>
            Étape 6: Entrez le mot de passe *****<br><br>
            Ou virement via MTN
        `,
        tips2: `
            Composez le #144#/n
            Étape 1: La première étape consiste à en choisir 1<br>
            Étape 2: Choisissez-en 1<br>
            Étape 3: Saisissez le numéro de téléphone portable à 10 chiffres au-dessus de 0xxxxxxxxx<br>
            Étape 4: Saisissez le montant du transfert xxx ci-dessus<br>
            Étape 5: Choisissez-en 1<br>
            Étape 6: Entrez le mot de passe****<br>
            Étape 7: Entrez le code de vérification aléatoire dans l'invite***<br><br>
            Ou virement via Orange
        `,
        tips3: `
            Ouvrez l'application Wave et suivez le processus Wave pour terminer le transfert.
        `
    },
    protocol: {
        navTitle: "Conditions d'utilisation et accord de confidentialité",
        t1: "1. Conditions d'utilisation de l'utilisateur",
        p1: 'Une information important:',
        p2: "Cet accord est un accord juridique entre vous (un individu ou une seule entité) et le développeur de cette application concernant la fourniture par le développeur de services liés à cette application pour vous. Voici un rappel spécial que les utilisateurs (vous) qui souhaitent utiliser des services associés doivent lire attentivement les termes de ces conditions d'utilisation à l'avance, y compris les clauses de non-responsabilité qui exemptent ou limitent les responsabilités des développeurs et les restrictions sur les droits des utilisateurs. Veuillez lire et accepter ou non ces conditions d'utilisation (les mineurs doivent être accompagnés d'un tuteur légal lors de la révision). Une fois ces conditions d'utilisation modifiées, le contenu modifié sera publié sur la page Web. Une fois que les conditions de service révisées seront publiées sur la page Web, elles remplaceront effectivement les conditions de service originales. Vous pouvez vous connecter au site Web à tout moment pour consulter la dernière version des Conditions d'utilisation. Si vous n'acceptez pas les modifications apportées aux conditions de service, vous ne devez pas utiliser ou annuler activement les services concernés. Dans le cas contraire, toute connexion, visualisation, etc., utilisation des services concernés sera considérée comme votre compréhension et votre acceptation de les modifications pertinentes et le présent Accord.",
        p3: "Si vous choisissez d'accepter cet accord et d'activer le service, cela signifie que vous acceptez d'être lié par les conditions de l'accord. Si vous n'acceptez pas ces conditions d'utilisation, vous n'avez pas le droit d'utiliser le service. Si vous violez l'une des dispositions des présentes Conditions, le développeur a le droit de suspendre ou de mettre fin à vos qualifications pour utiliser le service à tout moment et se réserve le droit de poursuivre les responsabilités légales pertinentes.",
        p4: "Sauf indication contraire, les termes suivants dans le présent Contrat ont les significations suivantes :",
        p5: "Développeur : Galaxy Entertainment",
        p6: "Application : chanceuxfootball",
        p7: "1. Cette application et ce service sont développés par des développeurs et protégés par des traités internationaux sur le droit d'auteur et d'autres lois et traités sur la propriété intellectuelle.",
        p8: "2. Les utilisateurs peuvent utiliser l'application et profiter des services conformément au présent accord sous réserve de se conformer à la loi et aux présentes conditions, mais les utilisateurs n'ont pas le droit d'effectuer des actions, y compris, mais sans s'y limiter, les suivantes :",
        p8_1: "2.1 Supprimez toutes les informations de droit d'auteur et le contenu de l'application et des autres copies ;",
        p8_2: "2.2 Ingénierie inverse, assemblage inverse, compilation inverse, etc. de l'application ;",
        p8_3: "2.3 Concernant les informations pertinentes contenues dans l'application, sans le consentement écrit du développeur, les utilisateurs ne sont pas autorisés à effectuer des actions comprenant, mais sans s'y limiter, les suivantes : utiliser, copier, modifier, lier, reproduire, compiler, publier, publier, établir sites miroirs, utiliser des « logiciels sans autorisation » « développer des produits dérivés, des œuvres, etc.",
        p8_4: "2.4 Utiliser des applications et des services pour publier, transmettre, diffuser et stocker des contenus qui mettent en danger la sécurité nationale, la réunification de la patrie et la stabilité sociale, ou des contenus insultants, diffamatoires, pornographiques, violents, qui provoquent de l'anxiété chez autrui, et tout contenu qui viole les lois, réglementations et politiques nationales.",
        p8_5: "2.5 Utiliser pour publier, transmettre, diffuser ou stocker du contenu qui enfreint les droits de propriété intellectuelle, les droits de secret commercial et d'autres droits légitimes d'autrui.",
        p8_6: "2.6 Adopter tout comportement mettant en danger la sécurité du réseau informatique, y compris, mais sans s'y limiter : utiliser des données non autorisées ou accéder à des serveurs/comptes non autorisés ; accéder à des réseaux informatiques publics ou aux systèmes informatiques d'autres personnes sans autorisation et supprimer, modifier et ajouter des informations stockées ; tenter de Sonder, analyser et tester les faiblesses de ce système ou réseau « Logiciel » sans autorisation, ou mener d'autres actions qui compromettent la sécurité du réseau ; Tenter d'interférer avec ou de perturber le fonctionnement normal de ce système ou site Web « Logiciel », et propager intentionnellement des logiciels malveillants. programmes ou virus et autres comportements qui perturbent et interfèrent avec les services d'information réseau normaux ; falsifier des noms de paquets TCP/IP ou des noms partiels.",
        p9: "3. Les utilisateurs doivent comprendre que l'utilisation de ce produit de service implique des services Internet et peut être affectée par des facteurs instables dans divers liens. Par conséquent, le service est soumis au risque d'interruption du service ou de non-respect des exigences de l'utilisateur causé par un cas de force majeure, des virus informatiques ou des attaques de pirates informatiques, l'instabilité du système, la localisation de l'utilisateur, l'arrêt de l'utilisateur et toute autre raison technique, Internet, de ligne de communication, etc. .",
        p10: "4. Les utilisateurs doivent comprendre que lors de l'utilisation de ce service, il y aura tout contenu ou comportement anonyme ou anonyme de la part de toute autre personne, y compris un contenu ou un comportement menaçant, diffamatoire, répréhensible ou illégal qui porte atteinte aux droits d'autrui (y compris les droits de propriété intellectuelle). .Les utilisateurs doivent supporter le risque d'usurpation d'identité. Le développeur n'offre aucun type de garantie pour le service, qu'elle soit explicite ou implicite, y compris l'authenticité, la qualité marchande, l'adéquation à un usage particulier et la propriété de toutes les informations pertinentes. la violation implique des garanties et conditions implicites et ne sera pas responsable de tout dommage direct, indirect, accidentel, spécial et ultérieur résultant de l'utilisation inappropriée ou illégale du service par l'utilisateur.",
        p11: "5. L'utilisation de ce service doit être conforme aux lois et politiques nationales en vigueur, sauvegarder les intérêts nationaux, protéger la sécurité nationale et respecter les présentes conditions. Toutes les responsabilités découlant de l'utilisation illégale de l'utilisateur ou de la violation de cet accord (y compris, mais sans s'y limiter, la publication de discours, transmission, etc.), l'utilisateur en porte l'entière responsabilité.",
        p12: "6. Le respect de la confidentialité des informations personnelles des utilisateurs est le principe de cette application et de ces services. Le développeur prendra des mesures raisonnables pour protéger les informations personnelles des utilisateurs. Sauf si la loi ou les services gouvernementaux dotés d'une autorité légale ou avec le consentement de l'utilisateur, etc. Avec le consentement de l'utilisateur, les informations personnelles de l'utilisateur ne seront pas divulguées à des tiers.",
        p13: "7. Il est particulièrement rappelé aux utilisateurs que lorsqu'ils utilisent Internet, ils doivent respecter les politiques et lois nationales pertinentes, telles que le droit pénal, la loi sur la sécurité nationale, la loi sur la confidentialité, les réglementations sur la protection de la sécurité des systèmes d'information informatique, etc., afin de protéger les intérêts nationaux et les intérêts nationaux. sécurité et pour ceux causés par l'utilisation illégale d'Internet. Toutes les responsabilités incombent à l'utilisateur.",
        p13_1: "7.1. Les utilisateurs ne doivent pas utiliser cette application et ces services pour envoyer ou diffuser des informations qui entravent l'ordre social ou qui sont illégales, fausses, harcelantes, insultantes, menaçantes, nuisibles, destructrices, provocatrices, obscènes et pornographiques.",
        p13_2: "7.2. Les utilisateurs ne sont pas autorisés à utiliser cette application et ces services pour envoyer ou diffuser des informations sensibles et des informations qui violent le système juridique national.",
        p13_3: "7.3. Cette application et ce service, comme la plupart des produits Internet, sont sensibles à divers problèmes de sécurité, notamment, mais sans s'y limiter :",
        p13_3_1: "1) Révéler des informations personnelles détaillées et être utilisé par des criminels pour provoquer du harcèlement dans la vie réelle.",
        p13_3_2: "2) Tromper et déchiffrer les codes.",
        p13_3_3: "3) D'autres logiciels téléchargés et installés contiennent des « chevaux de Troie » et d'autres virus qui menacent la sécurité des informations et des données sur les ordinateurs personnels, puis menacent l'utilisation de cette application et de ces services.",
        p14: "8. Il est conseillé aux utilisateurs de ne pas divulguer leurs différents comptes de propriété, cartes bancaires, cartes de crédit et mots de passe correspondants et autres informations importantes lors de l'utilisation de cette application et de ces services, faute de quoi les pertes qui en résulteraient seraient supportées par les utilisateurs eux-mêmes.",
        p15: "9. Les utilisateurs doivent utiliser cette application et ces services de manière standardisée et légale. Si un utilisateur perturbe, harcèle, trompe d'autres utilisateurs dans un environnement public ou commet d'autres violations du présent accord, le développeur a le droit d'arrêter le service une fois découvert. .",
        p16: "10. Les utilisateurs doivent comprendre que afin de répondre aux besoins du fonctionnement global de cette application, le développeur a le droit de modifier ou d'interrompre, de suspendre ou de mettre fin au service à tout moment sans vous en informer. Le développeur n'a pas besoin d'être responsable à vous ou à un tiers pour l'exercice des droits susmentionnés ou assumer toute responsabilité en matière d'indemnisation.",

        t2: "2. Accord de confidentialité de l'utilisateur",
        u1: "Cette application respecte et protège la vie privée de tous les utilisateurs du service. Afin de vous fournir des services plus précis et personnalisés, cette application utilisera et divulguera vos informations personnelles conformément à la présente politique de confidentialité. Cependant, cette application traitera ces informations avec un haut degré de diligence et de prudence. Sauf indication contraire dans la présente politique de confidentialité, cette application ne divulguera ni ne fournira ces informations à des tiers sans votre autorisation préalable. Cette application mettra à jour cette politique de confidentialité de temps en temps. Lorsque vous acceptez cet accord d'utilisation du service d'application, vous êtes réputé avoir accepté l'intégralité du contenu de cette politique de confidentialité. Cette politique de confidentialité fait partie intégrante de l'accord d'utilisation de ce service d'application.",
        u2: "1. Champ d'application",
        u2_1: "(a) Lorsque vous vous inscrivez pour ce compte de candidature, vous fournissez les informations d'inscription personnelles requises par cette candidature ;",
        u2_2: "(b) Lorsque vous utilisez ce service de réseau d'applications ou visitez cette page Web de la plate-forme d'applications, cette application reçoit et enregistre automatiquement les informations sur votre navigateur et votre ordinateur, y compris, mais sans s'y limiter, votre adresse IP, votre type de navigateur, des données telles que la langue utilisée, accéder à la date et à l'heure, aux informations sur les fonctionnalités logicielles et matérielles et aux enregistrements de pages Web dont vous avez besoin ;",
        u2_3: "(c) Données personnelles de l'utilisateur obtenues par cette application auprès de partenaires commerciaux par les voies légales.",
        u3: "Vous comprenez et acceptez que les informations suivantes ne s'appliquent pas à cette politique de confidentialité :",
        u3_1: "(a) Les informations sur les mots clés que vous saisissez lorsque vous utilisez le service de recherche fourni par cette plateforme d'application ;",
        u3_2: "(b) Les informations et données pertinentes collectées par cette application que vous publiez sur cette application, y compris, mais sans s'y limiter, la participation à des activités, les informations sur les transactions et les détails de l'évaluation ;",
        u3_3: "(c) Violation des dispositions légales ou violations des règles de cette application et des mesures que cette application a prises à votre encontre.",
        u4: "2. Utilisation des informations",
        u4_1: "(a) Cette application ne fournira, ne vendra, ne louera, ne partagera ou n'échangera pas vos informations personnelles avec un tiers non lié, à moins que vous n'obteniez votre autorisation au préalable, ou que le tiers et cette application (y compris les sociétés affiliées de cette application) individuellement ou conjointement. vous fournir des services, et après la fin du service, il lui sera interdit d'accéder à tous ces éléments, y compris ceux auxquels il pouvait accéder auparavant",
        u4_1: "(b) Cette application ne permet pas non plus à un tiers de collecter, modifier, vendre ou diffuser gratuitement vos informations personnelles par quelque moyen que ce soit. Si un utilisateur de cette plate-forme d'application s'engage dans les activités ci-dessus, dès sa découverte, cette application a le droit de résilier immédiatement le contrat de service avec cet utilisateur.",
        u4_1: "(c) Dans le but de servir les utilisateurs, cette application peut utiliser vos informations personnelles pour vous fournir des informations qui vous intéressent, y compris, mais sans s'y limiter, vous envoyer des informations sur les produits et services, ou partager des informations avec les partenaires de cette application afin qu'ils puissent Pour vous envoyer des informations sur ses produits et services (ces derniers nécessitent votre consentement préalable).",
        u5: "3. Divulgation d'informations",
        u6: "Dans les circonstances suivantes, cette application divulguera tout ou partie de vos informations personnelles en fonction de vos souhaits personnels ou de dispositions légales :",
        u6_1: "(a) Divulgué à des tiers avec votre consentement préalable ;",
        u6_2: "(b) Vos informations personnelles doivent être partagées avec des tiers afin de fournir les produits et services que vous demandez ;",
        u6_3: "(c) Divulgués à des tiers ou à des agences administratives ou judiciaires conformément aux dispositions pertinentes de la loi ou aux exigences des agences administratives ou judiciaires ;",
        u6_4: "(d) Si vous enfreignez les lois, réglementations en vigueur, le présent contrat de service d'application ou les règles connexes, vous devez le divulguer à un tiers ;",
        u6_5: "(e) Si vous êtes un plaignant qualifié en matière de propriété intellectuelle et que vous avez déposé une plainte, divulguez-la au défendeur à la demande du défendeur afin que les deux parties puissent gérer d'éventuels litiges relatifs aux droits ;",
        u6_6: "(f) Dans une transaction créée sur cette plateforme d'application, si une partie à la transaction exécute ou exécute partiellement ses obligations de transaction et fait une demande de divulgation d'informations, cette application a le droit de décider de fournir à l'utilisateur les coordonnées de la contrepartie et autres informations nécessaires.Informations destinées à faciliter la réalisation des transactions ou la résolution des litiges.",
        u6_7: "(g) Autres divulgations que cette application juge appropriées conformément aux lois, réglementations ou politiques du site Web.",
        u7: "4. Stockage et échange d'informations",
        u8: "Les informations et les documents collectés par cette application à votre sujet seront stockés sur les serveurs de cette application et/ou de ses sociétés affiliées. Ces informations et documents peuvent être transférés vers votre pays, région ou en dehors du pays où cette application collecte des informations et des documents, et dans Consulté, stocké et affiché à l’étranger.",
        u9: "5. Sécurité des informations",
        u9_1: "(a) Ce compte d'application dispose de fonctions de protection de sécurité. Veuillez conserver correctement vos informations de nom d'utilisateur et de mot de passe. Cette application utilisera des mesures de sécurité telles que le cryptage des mots de passe des utilisateurs pour garantir que vos informations ne soient pas perdues, utilisées abusivement ou altérées. Malgré les mesures de sécurité mentionnées ci-dessus, veuillez également noter qu'il n'existe pas de « mesures de sécurité parfaites » sur le réseau d'information.",
        u9_2: "(b) Lorsque vous utilisez ce service de réseau d'applications pour des transactions en ligne, vous divulguerez inévitablement vos informations personnelles, telles que vos coordonnées ou votre adresse postale, à la contrepartie ou à la contrepartie potentielle. Veuillez protéger correctement vos informations personnelles et les fournir à des tiers uniquement lorsque cela est nécessaire. Si vous constatez que vos informations personnelles ont été divulguées, notamment le nom d'utilisateur et le mot de passe de cette application, veuillez contacter immédiatement le service client de cette application afin que cette application puisse prendre les mesures correspondantes.",
        u10: "6. Modifications de cette politique de confidentialité",
        u10_1: "(a) Si nous décidons de modifier notre politique de confidentialité, nous publierons ces modifications dans cette politique, sur le site Web de notre entreprise et là où nous le jugeons approprié afin que vous puissiez comprendre comment nous collectons et utilisons vos informations personnelles et qui peut accéder à ces informations, et dans quelles circonstances nous divulguerons ces informations.",
        u10_1: "(b) La société se réserve le droit de modifier cette politique à tout moment, veuillez donc la vérifier fréquemment. S'il y a des changements majeurs dans cette politique, la société vous en informera via une notification sur son site Web.",
        end: "Veuillez protéger correctement vos informations personnelles et les fournir à des tiers uniquement lorsque cela est nécessaire. Si vous constatez que vos informations personnelles ont été divulguées, notamment le nom d'utilisateur et le mot de passe de cette application, veuillez contacter immédiatement le service client de cette application afin que cette application puisse prendre les mesures correspondantes."
    }
}