import { createI18n } from "vue-i18n";
import en from "./modules/en";
import fr from "./modules/fr";
import pt from "./modules/pt";

const messages = {
    "en-US": en,
    "fr-FR": fr,
    "pt-PT": pt
}

const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: "fr-FR",
    messages
});

export {
    i18n
};