import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import './assets/styles/global.css';

import { i18n } from './lang';

import {
    Button,
    Icon,
    NavBar,
    Picker,
    Popup,
    Toast,
    showToast,
    ActionSheet
} from "vant";
import "vant/es/toast/style";

const app = createApp(App);
app.use(NavBar).use(Button).use(Picker).use(Popup).use(Icon).use(Toast).use(ActionSheet);
app.config.globalProperties.$toast = showToast;
app.use(store).use(router).use(i18n);
app.mount('#app');
